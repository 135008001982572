import Typography from '@material-ui/core/Typography';
import React from 'react'
import { useStyles } from './styles'

function Header({ header, titleColor }) {

    const classes = useStyles();

    return (
        <Typography 
            style={{ 
                color: titleColor
            }}
            variant="body1" 
            className={classes.headerTitle}
        >
            {header}
        </Typography>
    )
}

export default Header