import Typography from '@material-ui/core/Typography';
import React from 'react'
import { useStyles } from './styles'

function PostAge({ postAge }) {

    const classes = useStyles();

    return (
        <div className={classes.postAgeContainer}>
            <Typography variant="caption"style={{fontSize:"1rem"}}>{postAge}</Typography>
        </div>
    )
}

export default PostAge
