import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#242423',
    marginBottom: theme.spacing(2),
    textDecoration: 'none',
  },
  RepliedRoot: {
    display: 'flex',
    // backgroundColor: '#242423',
    backgroundColor: '#1F2022',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(5),
    textDecoration: 'none',
  },
  details: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    color: '#FFFFFF',
    padding:"0 8px" //
  },
  cover: {
    width: '50%',
  },
  cardDescription: {
    color: '#181818',
    display: '-webkit-box',
    "overflow": "hidden",
    '-webkit-line-clamp': 2, /* number of lines to show */
    "text-overflow": "ellipsis",
    '-webkit-box-orient': 'vertical',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  popularTypography: {
    fontSize: 'calc(12px + 1vmin)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  popularCardDescription: {
    color: '#181818',
    //overflow: 'hidden',
    //textOverflow: 'ellipsis',
    display: '-webkit-box',
    "overflow": "hidden",
    "text-overflow": "ellipsis",
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  views: {
    color: '#E8EDDF',
    display: "flex",
    alignItems: "center",
  },
  cardAction: {
    justifyContent: 'space-between',
    padding: theme.spacing(0.4, 1),
  },
  Icon: {
    fontSize: '1rem',
    color: '#E8EDDF',
    margin: theme.spacing(-0.2, 0),
  },
  numberCount: {
    margin: theme.spacing(0, 1.1, 0, 0.8),
  },
  emojis: {
    textAlign: 'right',
    background: 'transparent',
    color: '#fff',

    '&:hover': {
      background: 'transparent',
    }
  },
  badge: {
    borderRadius: '10px',
    transform: 'translate(60%, -50%)',
    backgroundColor: '#fff',
    color: '#000',
    textAlign: 'center',
    fontSize: '12px',
    padding: '2px',
  },

  flex: {
    display: 'flex',
  },

  anouncementIcon: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    margin: '2px 2rem',
  },

  goldenFontWeight: {
    color: "#000",
    fontWeight: 400,
    fontSize: "1.1rem",
    margin:"4px 4px 2px 4px"
  },
  anouncementBadge: {
    color: '#fff',
    backgroundColor: '#000'
  },
  normalBadge: {
    color: '#000',
    backgroundColor: '#fff'
  },
  cardTitle: {
    color: '#000',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  anouncementEmoji: {
    color: "#000"
  },
  textRight:{
    justifyContent: 'flex-end',
    paddingBottom: "3px",
    paddingTop: "3px",
  },
  contentWarning: {
    marginTop: theme.spacing(1),
    color: '#FFFFFF',
    "& h5": {
      margin: 0,
      paddingLeft: "4px",
      fontSize: "1rem",
      fontWeight: "350"
    }
  }
}));
