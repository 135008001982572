import React, { useRef, useState, useEffect } from 'react';
import CardActions from '@material-ui/core/CardActions';
import axios from 'axios';
import Chip from '@material-ui/core/Chip';
import { useStyles } from '../Components/styles/CardStyles'

const fetchEmoticonsData = async (url, id) => {
    return axios
        .get(`${url}${id}`)
        .then(({ data }) => data)
        .catch(err => console.error(err));
}

function Emoticons(props) {

    const fetchEmoticons = useRef(() => { })
    const classes = useStyles();
    const [EmoticonData, setEmoticonData] = useState([]);

    fetchEmoticons.current = (url, id) => {
        fetchEmoticonsData(url, id)
            .then((response) => {
                if (response) {
                    setEmoticonData(response.data)
                }
            })
    }

    useEffect(() => {
        if (!props.anouncement) {
            fetchEmoticons.current('/api/api/v1/get-used-emoticon-for-article/', props.id);
        }
        else {
            fetchEmoticons.current('/api/api/v1/announcement/emoticon-used/', props.id);
        }
    }, [])

    return (
        <CardActions className={classes.textRight} style={{paddingRight:"0", marginleft:"0"}}>
            {EmoticonData.map((item, i) => (
                <Chip
                    className={props.anouncement ? [classes.emojis, classes.anouncementEmoji].join(' '): classes.emojis}
                    key={i}
                    icon={
                        <img
                            src={`/newEmoji/${item.emoticon.text_icon}.png`}
                            alt={item.emoticon.text_icon}
                            loading="lazy"
                            className={props.anouncement ? [classes.anouncementEm, "home_page_emoji"].join(' ') : "home_page_emoji"}
                            width="25"
                            height="25"
                            style={{
                                marginLeft: "3px",
                            }}
                        />

                    }
                    label={item.no_of_hit}
                    style={{
                        marginLeft: 0,
                        color: props.anouncement ? "#000" : "#fff",
                        background: 'transparent'
                    }}
                />
            ))}
        </CardActions>
    )
}

export default Emoticons
