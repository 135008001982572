import VideoCard from './VideoCard';
import React from 'react'
import Divider from '@material-ui/core/Divider';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    verticalDivider: {
        margin: '0px 10px',
        height: '80%',
        alignSelf: 'center',
        width: '2px',
        backgroundColor: '#444444c7'
    },
    horizontalDivider: {
        margin: '0px 10px',
        height: '1px',
        alignSelf: 'center',
        width: '80%',
        backgroundColor: '#444444c7'
    },
}))

export default function VideoSession(props) {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width: 959px)');

    return (
        <>
            {
                mobile &&
                <>
                    {/* <Typography component="h5" variant="h5" className={classes.talkTitle}>
                        Talks
                    </Typography>
                    <div className="scrolling-block">
                        <div class="scrolling-wrapper-flexbox">
                            {
                                props.talks && props.talks.map((video, idx) => (
                                    <>
                                        <VideoCard video={video} key={idx} />
                                        <Divider orientation="vertical" key={idx} flexItem className={classes.verticalDivider} />
                                    </>
                                ))
                            }
                        </div>
                    </div> */}

                    {
                        props.talks && props.talks.map((video, idx) => (
                            <>
                                <VideoCard video={video} key={idx} />
                                <Divider orientation="horizontal" key={idx} flexItem className={classes.horizontalDivider} />
                            </>
                        ))
                    }
                </>
            }
            {
                !mobile &&
                <>
                    {
                        props.talks && props.talks.length >0 && props.talks.map((video, idx) => (
                            <div key={idx + 5000}>
                                <VideoCard video={video} />
                                <Divider flexItem className={classes.verticalDivider} />
                            </div>
                        ))
                    }
                </>
            }
        </>
    )
}


