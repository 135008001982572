import React, { useEffect, useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import { useStyles } from '../../containers/styles';
import Typography from '@material-ui/core/Typography';
import PostAge from '../../containers/PostAge'
import CardActionArea from '@material-ui/core/CardActionArea';
import Link from 'next/link';
// import * as cardUtils from './MomentUtil';
import HomeCardBottom from './HomeCardBottom';
import dynamic from 'next/dynamic';

const ContentWarning = dynamic(() => import("../../../../shared/Components/ContentWarning"))

const warningText = "Warning: 18+ Content."

function ArticleCard(props) {
    const classes = useStyles();
    const setPostAgeUsingMoment = useRef(() => { })
    const [postAge, setPostAge] = useState('')

    setPostAgeUsingMoment.current = () => {
        import('moment')
            .then(({ default: moment }) => {
                moment.updateLocale('en', {
                    relativeTime: {
                        future: "in %s",
                        past: "%s ago",
                        s: '%ds',
                        ss: '%ds',
                        m: "%dm",
                        mm: "%dm",
                        h: "%dh",
                        hh: "%dh",
                        d: "%dd",
                        dd: "%dd",
                        w: "%dw",
                        ww: "%dw",
                        M: "%dmo",
                        MM: "%dmo",
                        y: "a yr",
                        yy: "%dyrs"
                    }
                });
                moment.relativeTimeThreshold('s', 59);
                moment.relativeTimeThreshold('ss', 59);
                moment.relativeTimeThreshold('m', 59);
                moment.relativeTimeThreshold('mm', 59);
                moment.relativeTimeThreshold('h', 23);
                moment.relativeTimeThreshold('hh', 23);
                moment.relativeTimeThreshold('dd', 29);
                //moment.relativeTimeThreshold('w', 4);  // enables weeks
                moment.relativeTimeThreshold('MM', 11);
                setPostAge(
                    moment(new Date(Date.parse(props.created_on)))
                        .fromNow(true))
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        setPostAgeUsingMoment.current();
    }, [])

    return (
        <Link href={
            props.anouncement ? `/announcement/${props.slug}` : `${props.get_share_url}`
        } prefetch={false}>
            <a className={classes.link}>
                <Card
                    className={classes.rootCard}
                    style={{
                        backgroundColor: props.bgColor,
                        borderBottom: `2px solid ${props.borderColor}`
                    }}
                >
                    <CardActionArea>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography component="h6"
                                variant="h6" className={props.anouncement ? [classes.cardtitle, classes.anouncementTitle].join(" ") : classes.cardtitle}>
                                {props.title}
                            </Typography>
                            {!props.anouncement && <PostAge postAge={postAge} />}
                        </div>
                        <Typography variant="body1"
                            className={props.anouncement ? [classes.cardDescription, classes.anouncementDescription].join(" ") : classes.cardDescription}
                        >
                            {props.description}
                        </Typography>
                        {props.adultry_flag &&
                            <div className={classes.contentWarning} >
                                <ContentWarning warningText={warningText} />
                            </div>
                        }
                        <HomeCardBottom {...props} classes={classes}/>
                    </CardActionArea>
                </Card>
            </a>
        </Link>
    )
}

export default ArticleCard;
