import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    homeIntroContainer:{
        backgroundColor: '#000',
        color: '#000',
        textAlign: 'center',
        padding: theme.spacing(1),
        margin: theme.spacing(0, 2),
        border: '0.5px solid rgb(16, 174, 79)'
    },
    introTitle:{ 
        fontSize: '1.6rem',
        fontWeight: 'bold'
    },
    introSubtitle:{
        fontSize: '1rem',
        fontWeight: 'bold',
        marginTop: theme.spacing(1)
    },
    caption:{
        fontWeight: 100,
        marginTop: theme.spacing(1),
    },
    startWritingInput:{
        margin: theme.spacing(0, 2),
        padding: theme.spacing(0,1,0.3,1),
        width: 'auto',
        height:"auto",
        color: '#000',
        fontWeight: 'bold',
        boxSizing: "border-box",
        border: "1px solid #6d6d6d",
        borderRadius: '5px',
        // ['@media (min-width:768px)']: {
        //     border: "1px solid #6d6d6d",
        //     borderRadius: '5px',
        // },

        // "& input": {
        //     height: 50,
        //     padding: theme.spacing(2),
        //     backgroundColor: '#6d6d6d',
        //     border: 'none',
        //     borderRadius: '5px',
        //     width: '100%'
        // }
    },
    // backgroundImg: {
    //     backgroundImage: "url(\"/img/top.png\")",
    //     minHeight: "67px",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "100%",
    //     // ['@media (min-width:768px)']: {
    //     //     backgroundImage: "url(\"/img/banner_desk.png\")",
    //     //     minHeight: "79px",
    //     // },
    // },

    writtingButton: {
        width: 'fit-content',
        padding:"4px 8px",
        marginTop:"5px",
        color: '#fff',
        borderRadius: '5px',
        backgroundColor: '#0FAE4F',
        fontWeight: 'bold',
        cursor: "pointer",
        // position: "relative",
        // top: '12px',
        // ['@media (max-width:768px)']: {
        //     position: "relative",
        //     top:'28px'
// },
    },

    buttonPosition: {
        display: "flex",
        justifyContent:"center"
    },
    
    textAlign: {
        textAlign:"center"
    },

    rootCard: {
        margin: theme.spacing(1, 1.1, 0.5, 1.1),
        padding: theme.spacing(0.4,1,0,1),
        textDecoration: 'none',
        color: '#fff',
        borderRadius: "4px",
        ['@media (min-width:768px)']: {
            marginLeft: "16px"
        },
    },
    cardtitle: {
        maxWidth: '88%',
        color: '#000000',
        fontWeight: 500,
        marginBottom: theme.spacing(0.5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 1 /* number of lines to show */,
        '-webkit-box-orient': 'vertical',
        ['@media (max-width:768px)']: {
            fontSize:"1.1rem"
        },
    },

    anouncementTitle: {
            color: '#000',
            fontWeight: 400,
    },

    headerTitle:{
        margin: theme.spacing(0.5, 2, 0.5, 1.1),
        color: 'rgb(16, 174, 79)',
        fontWeight: 600,
        ['@media (min-width:768px)']: {
            margin: theme.spacing(0.5, 2),
        },
    },
    postAgeContainer:{
        textAlign: 'right',
        color: '#717171',
        margin: "0px 0 4px 5px",
    },
    cardDescription: {
        color: '#181818',
        display: "-webkit-box",
        "overflow": "hidden",
        "text-overflow": "ellipsis",
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 2,

        ['@media (min-width:500px)']: {
            "-webkit-line-clamp": 2,
        },
        ['@media (max-width:600px)']: {
            fontSize:'14px'
        },
    },

    anouncementDescription: {
        color: '#717171',
        fontWeight: 400,
    },

    iconData:{
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(0),
        padding:"3px 0 6px 0",
        color: '#E8EDDF',
    },

    icon:{
        color: '#E8EDDF',
        fontSize: '1rem',
        margin: theme.spacing(0, 0)
    },

    anouncementIcon: {
        color: "#717171",
        fontWeight: 400,
        fontSize: "1rem",
        margin: "4px",
        marginTop: "1px",
    },

    anouncementIconBG: {
            borderRadius: '50%',
            backgroundColor: "#717171",
    },

    mainAnouncementIcon: {
        width: 20,
        height: 20,
        borderRadius: '50%',
        margin: '2px 0.5rem',
    },

    numberCount: {
        margin: theme.spacing(0, 1.1, 0, 0.8),
    },
    link:{
        textDecoration: 'none'
    },
    adsByGoogle: {
        width: '300px',
        height: '50px',
    
        [theme.breakpoints.up('sm')]: {
            width: '468px',
            height: '60px',
        },
    
        [theme.breakpoints.up('md')]: {
            width: '728px',
            height: '90px',
        },
    },
    // centerAds: {
    //     display: "flex",
    //     justifyContent: "center",
    //     margin: "auto",
    //     width: '316px',
    //     height: '58px',

    //     [theme.breakpoints.up('md')]: {
    //         width: '744px',
    //         height: '98px',
    //     },
    // },
    flex: {
        display:"flex"
    },
    contentWarning: {
        marginTop: theme.spacing(0.8),
        color: '#FFFFFF',
        "& h5": {
            margin: 0,
            paddingLeft: "4px",
            fontSize: "1rem",
            fontWeight:"300",
        },
    },
}));
