import React, { useEffect, useState } from 'react';
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography';

function VideoCard(props) {
    const classes = useStyles();
    const [authorName, setAuthorName] = useState(props.video.author_name)

    useEffect(() => {
        {/**sepearate this in a function */}
        if (props.video.hasOwnProperty('author_name') && !props.nextVideos) {
            const updatedAuthorName = props.video.author_name.replace(/\s/g, '-')
            setAuthorName(updatedAuthorName)
        }
        else if(props.video.hasOwnProperty('author_detail')) {
            const updatedAuthorNameForNextVideos = props.video.author_detail[0].author_name.replace(/\s/g, '-')
            setAuthorName(updatedAuthorNameForNextVideos)
        }
    }, [])

    return (
        <a href={`/talk/${authorName}/${props.video.slug}`} className={classes.anchor}>
                <div className={props.moreVideos ? 'more_videoCard videoCard' : 'videoCard'}>
                    <div className={classes.videoCardImageBlock}>
                        <img src={props.video.video_thumbnail_link} alt="video" className={classes.videoWrapperImage} />
                    </div>
                    <div className={classes.videoCardContent}>
                        <Typography
                            component="h6"
                            variant="h6"
                            className={
                                props.moreVideos ? `${classes.videoCardAuthor} ${classes.mt0}` : classes.videoCardAuthor
                            }>
                            {props.video.video_title}
                        </Typography>
                        {
                            props.video && props.nextVideos && <Typography component="p" variant="body2" className={classes.videoCardTitle}>
                                {props.video.author_detail[0].author_name}
                            </Typography>
                        }
                        {
                            props.video && !props.nextVideos && <Typography component="p" variant="body2" className={classes.videoCardTitle}>
                                {props.video.author_name}
                            </Typography>
                        }
                    </div>
                </div>
            </a>
    );
}

export default VideoCard
