import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    videoCardWrapper: {
        marginBottom: theme.spacing(1),
        width: 500,
    },
    block: {
        display: 'flex',
        height: 100,
        justifyContent: 'space-between',
    },
    videoCardImageBlock: {
        width: 130,
        minWidth: 100,
        maxWidth: 150,
        padding: theme.spacing(1),
        ['@media (min-width:900px)']: {
            width: 'auto',
            maxWidth: 'auto',
        },
        ['@media (max-width:375)']: {
            width: 80,
            maxWidth: 205,
        },
    },
    videoWrapperImage:{
        width: '100%',
        height: '100%'
    },
    videoCardContent: {
        width: '180px',

        ['@media (max-width: 320px)']: {
            width: 110,
        },

        ['@media (max-width: 425px)']: {
            width: 130,
        },

        ['@media (min-width:900px)']: {
            width: 'auto',
        },
    },
    videoCardAuthor: {
        color: '#fff',
        fontSize: '16px',
        maxHeight: '100px',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    mt0: {
        marginTop: theme.spacing(0)
    },
    videoCardTitle: {
        color: '#717171',
        marginTop: theme.spacing(1),
        maxHeight: '40px',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    videoSessionWrapper:{
        ['@media (min-width:900px)']: {
            width: '65%',
            margin: theme.spacing(0, 2),
            minWidth: '320px',
            maxWidth: '900px'
        },
    },
    videoSessionContent: {
        padding: theme.spacing(1, 2)
    },
    videoSessionTitle:{
        color: '#fff'
    },
    AuthorViews: {
        color: '#717171'
    },
    description: {
        color: '#717171',
        marginTop: theme.spacing(1),
        maxHeight: '100px',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    detailedDescription: {
        color: '#717171',
    },
    SkeletonCard: {
        backgroundColor: 'grey',
        borderRadius: '0.25rem',
        width: '100%',
    },
    bgWhite: {
        margin: theme.spacing(1, 1),
        backgroundColor: '#717171'
    },
    message: {
        "& .MuiOutlinedInput-root": {
            background: "#717171",
            outline: 'none',
            color: '#fff',
            border: 'none'
        },
        "&..MuiInputBase-root": {
            color: '#fff'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#fff',
        },
    },
    commentButtons: {
        textAlign: 'right',
        margin: theme.spacing(1, 0),

    },
    flatButton: {
        color: 'rgb(252, 163, 17)'
    },
    commentButton: {
        margin: theme.spacing(0, 1),
        backgroundColor: 'rgb(252, 163, 17)'
    },
    questionHeading: {
        color: 'rgb(252, 163, 17)'
    },
    questionWrapper: {
        color: '#fff'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    mx: {
        margin: theme.spacing(0, 1)
    },
    anchor: {
        textDecoration: 'none'
    },
    viewButtonAlign: {
        margin: theme.spacing(1, 0),
        textAlign: 'right'
    },
    viewMoreButton: {
        color: 'rgb(252, 163, 17)'
    },
    inlineFlex: {
        display: 'flex',

        ['@media (max-width:900px)']: {
            display: 'block'
        }
    },
    moreVideosWrapper: {
        position: '-webkit-sticky',
        position: 'sticky',
        top: '140px',
        alignSelf: 'flex-start'
    },
    watchNextHeading: {
        margin: theme.spacing(0, 2),

        ['@media (max-width:900px)']: {
            margin: theme.spacing(5, 2)
        }
    },
    paper: {
        backgroundColor: '#242423',
        color: theme.palette.text.secondary,
    },
    answerDivider: {
        backgroundColor: '#ffffff5e',
    },
    questionDivider: {
        backgroundColor: '#ffffff5e',
        margin: '0px -15px'
    },
    expertChip: {
        borderRadius: '0px',
        color: '#fff',
        backgroundColor: 'purple',
        margin: theme.spacing(1, 2)
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    talkTitle: {
        margin: theme.spacing(1, 2),
        color: 'rgb(252, 163, 17)'
    }
}));
