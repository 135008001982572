import React from 'react';
import Typography from '@material-ui/core/Typography';
import views from '../../../../assets/img/view.png';
import reply from '../../../../assets/img/reply.png';
import Emoticons from '../../containers/Emoticons';
import anouncementIcon from '../../../../assets/img/bullhorn.png';

const HomeCardBottom = (props) => {
    return (
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <Typography variant="caption" component="div" className={ props.anouncement ? [props.classes.iconData, props.classes.anouncementIcon].join(" ") : props.classes.iconData}>
                <img src={!props.anouncement ? views : "/img/view_black.png"} alt="views" loading="lazy" height="18" width={!props.anouncement ? "18" : "20"}
                    className={props.classes.icon}
                />
                <span className={props.classes.numberCount}>{props.num_of_views}</span>
                {props.reply_count > 0 ? (
                    <>
                        <img src={!props.anouncement ? reply : "/img/reply_black.png"} alt="reply" loading="lazy" height="18" width={!props.anouncement ? "18": "20"}
                            className={props.classes.icon}

                        />
                        <span className={props.classes.numberCount}>{props.reply_count}</span>
                    </>
                ) : null}
                {props.anouncement && (
                    <img
                        src={anouncementIcon}
                        loading="lazy"
                        className={props.classes.mainAnouncementIcon}
                    />
                )}
            </Typography>
            <Emoticons {...props} />
        </div>
    );
}
export default HomeCardBottom;